import React, { useEffect } from 'react';
import Container from '@mui/material/Container';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import API from '../../datalayer/API';
import { OrderType, RefundReason } from '../../models/DashboardOrder';

const WriteOffOverview = ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
	const [loading, setLoading] = React.useState(false);
	const [writeOffs, setWriteOffs] = React.useState<Array<{ code: String; quantity: number }>>([]);

	useEffect(() => {
		const fetchOrders = async () => {
			setLoading(true);
			try {
				const orders = await API.getOrders({
					type: OrderType.REFUND,
					startDate,
					endDate,
				});
				const writeOffs = new Map<string, number>();
				orders.forEach((order) => {
					order.refundRequest?.refundItems.forEach((item) => {
						if (item.reason === RefundReason.ERROR_I_DID_NOT_BUY_THAT) return;
						if (writeOffs.has(item.upc)) {
							writeOffs.set(item.upc, writeOffs.get(item.upc)! + 1);
						} else {
							writeOffs.set(item.upc, 1);
						}
					});
				});
				const array = Array.from(writeOffs.entries()).map(([code, quantity]) => ({ code, quantity }));
				setWriteOffs(array.sort((a, b) => b.quantity - a.quantity));
			} catch (e) {
				console.error(e);
			} finally {
				setLoading(false);
			}
		};
		fetchOrders();
	}, [startDate, endDate]);

	return (
		<Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
			<Paper
				sx={{
					display: 'flex',
					flexDirection: 'column',
					mb: 4,
				}}
			>
				<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
					<Typography variant='h6' sx={{ pt: 2, pl: 2, pr: 2, pb: 2 }}>
						Refunded Items to Write Off
					</Typography>
				</Box>
				<TableContainer>
					<Table aria-label='simple table'>
						<TableHead>
							<TableRow>
								<TableCell>Code</TableCell>
								<TableCell align='right'>Quantity</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{writeOffs.map((item) => (
								<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
									<TableCell component='th' scope='row'>
										{item.code}
									</TableCell>
									<TableCell align='right'>{item.quantity}</TableCell>
								</TableRow>
							))}
							{loading ? (
								<TableRow>
									<TableCell align='center' colSpan={100}>
										<Typography variant='body2'>Loading...</Typography>
									</TableCell>
								</TableRow>
							) : null}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</Container>
	);
};

export default WriteOffOverview;
