import { Contactless, DoorSliding, HourglassBottom, QrCodeScanner, ReceiptLong, Store } from '@mui/icons-material';
import CustomProgressBar from './CustomProgressBar';
import DashboardJourney, { CustomerJourneyState, JourneyType } from '../../models/DashboardJourney';

const JourneyProgressBar = ({ journey }: { journey: DashboardJourney }) => {
	const states = [];

	const isGateCheckinJourney =
		journey.stateMillis[CustomerJourneyState.ANNOUNCEMENT] &&
		journey.stateMillis[CustomerJourneyState.SCAN] &&
		journey.stateMillis[CustomerJourneyState.SCAN] - journey.stateMillis[CustomerJourneyState.ANNOUNCEMENT] < 1000;

	if (isGateCheckinJourney) {
		states.push({
			state: CustomerJourneyState.SCAN,
			label: journey.type === JourneyType.APP_IN ? 'QR code' : 'Card',
			sublabel: journey.type === JourneyType.APP_IN ? 'scanned at' : 'tapped at',
			icon: journey.type === JourneyType.APP_IN ? QrCodeScanner : Contactless,
		});
	} else if (journey.type === JourneyType.APP_IN) {
		states.push({
			state: CustomerJourneyState.ANNOUNCEMENT,
			label: 'QR code',
			sublabel: 'generated at',
			icon: QrCodeScanner,
		});
		if (journey.state === CustomerJourneyState.ANNOUNCEMENT || journey.stateMillis[CustomerJourneyState.SCAN] != null) {
			states.push({
				state: CustomerJourneyState.SCAN,
				label: 'QR Code Scan',
				sublabel: 'processed at',
				icon: DoorSliding,
			});
		}
	} else if (journey.type === JourneyType.TAP_IN) {
		states.push({
			state: CustomerJourneyState.ANNOUNCEMENT,
			label: 'Card',
			sublabel: 'tapped at',
			icon: Contactless,
		});
	}

	states.push({
		state: CustomerJourneyState.CHECKIN,
		label: 'Shopping',
		sublabel: 'entered the store at',
		icon: Store,
	});
	states.push({
		state: CustomerJourneyState.LEFT,
		label: 'Waiting for their receipt',
		sublabel: 'left the store at',
		icon: HourglassBottom,
	});
	states.push({
		state: CustomerJourneyState.COMPLETED,
		label: 'Basket from AiFi',
		sublabel: 'received at',
		icon: ReceiptLong,
	});

	return <CustomProgressBar activeState={journey.state} stateMillis={journey.stateMillis} states={states} />;
};

export default JourneyProgressBar;
