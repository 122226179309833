export default interface DashboardJourney {
	_id: string;
	type: JourneyType;
	customerId: number;
	transactionId: string | null;
	aifiCustomerId: number | null;
	aifiSessionId: string | null;
	sub: string;
	verificationCode: string;
	exitCode: string | null;
	entryGateId: string | null;
	currentCustomerAmount: number;
	maxCustomerAmount: number;
	ageVerificationNecessary: boolean;
	ageVerificationSuccessful: boolean;
	suspiciousBehaviourDetected: boolean;
	state: CustomerJourneyState;
	stateMillis: any;
	cancelled: boolean;
	receiptViewed: boolean;
	interactionCount: number;
	restrictedInteractionCount: number;
}

export enum JourneyType {
	APP_IN = 'APP_IN',
	TAP_IN = 'TAP_IN',
}

export enum CustomerJourneyState {
	ANNOUNCEMENT = 'announcement',
	SCAN = 'scan',
	CHECKIN = 'checkin',
	LEFT = 'left',
	COMPLETED = 'completed',
}
