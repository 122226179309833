import React, { useState } from 'react';
import Container from '@mui/material/Container';
import { Alert, Box, Button, Divider, Grid, Paper, Snackbar, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import {
	Error,
	AccountCircle,
	DoorSlidingOutlined,
	Group,
	HourglassBottom,
	Liquor,
	Person,
	ReceiptLong,
	Badge,
	Videocam,
	Screenshot,
	Store,
	Replay,
	FormatListBulleted,
	ProductionQuantityLimits,
	ContentPaste,
	Receipt,
	Tablet,
	Smartphone,
	Contactless,
	AutoAwesome,
	SwitchAccessShortcut,
} from '@mui/icons-material';
import JourneyProgressBar from '../components/Journey/JourneyProgressBar';
import OrderPaymentProgressBar from '../components/Journey/OrderPaymentProgressBar';
import API from '../datalayer/API';
import DashboardJourney, { CustomerJourneyState, JourneyType } from '../models/DashboardJourney';
import DashboardOrder, { BopState, ManualProductOverride, OrderType, PaymentState } from '../models/DashboardOrder';
import AiFiEvent from '../models/AiFiEvent';
import ScanEvent from '../models/ScanEvent';
import EventItem from '../components/General/EventItem';
import JourneyEventSimulationControls from '../components/Journey/JourneyEventSimulationControls';
import { RoleGate } from '../components/General/RoleGate';
import { StaffRole } from '../models/StaffRole';
import CheckinEvent from '../models/CheckinEvent';
import { EventType } from '../models/EventType';
import eventEmitter from '../datalayer/EventEmitter';
import TapAppInEvent from '../models/TapAppInEvent';
import { AiFiProduct } from '../models/AiFiProduct';

function useQuery() {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}

const JourneyDetailPage = () => {
	const query = useQuery();

	const [state, setState] = useState<'loading' | 'error' | 'completed'>('loading');

	const [journey, setJourney] = useState<DashboardJourney | null>(null);
	const [orders, setOrders] = useState<Array<DashboardOrder>>([]);
	const [liveReceipt, setLiveReceipt] = useState<Array<AiFiProduct>>([]);
	const [events, setEvents] = useState<Array<AiFiEvent | ScanEvent | TapAppInEvent | CheckinEvent>>([]);

	const [snack, setSnack] = useState<{ message: string; severity: 'success' | 'error' | 'info' | 'warning' } | null>(null);

	const loadData = async () => {
		const keys = ['customerId', 'aifiSessionId', 'verificationCode', 'exitCode'];
		let key = null;
		let value = null;
		for (let i = 0; i < keys.length; i++) {
			if (query.get(keys[i])) {
				key = keys[i];
				value = query.get(keys[i]);
				break;
			}
		}
		if (!key || !value) {
			return;
		}
		try {
			const journey = await API.getJourney(key, value);
			setJourney(journey);
			setOrders(await API.getOrdersForJourney(journey.customerId));
			setEvents(
				await API.getEvents({
					journeyCustomerId: journey.customerId ?? undefined,
					aifiSessionId: journey.aifiSessionId ?? undefined,
					entryCode: journey.verificationCode ?? undefined,
					exitCode: journey.exitCode ?? undefined,
				})
			);
			setLiveReceipt(
				[CustomerJourneyState.CHECKIN, CustomerJourneyState.LEFT].includes(journey.state) ? await API.getLiveReceipt(journey.customerId) : []
			);
			setState('completed');
		} catch (e) {
			console.error(e);
			setState('error');
		}
	};

	const retryOrder = async (order: DashboardOrder) => {
		setSnack({ message: 'Processing order...', severity: 'info' });
		try {
			const data: any = await API.retryOrder(order.orderReceiptId);
			if (data?.results?.length > 0 && data.results[0].retrySuccessful) {
				setSnack({ message: 'Order processing successful', severity: 'success' });
				setTimeout(() => {
					loadData();
				}, 1000);
			} else {
				setSnack({ message: 'Order processing failed', severity: 'error' });
			}
		} catch (e) {
			console.error(e);
			setSnack({ message: 'Order processing failed', severity: 'error' });
		} finally {
			setTimeout(() => {
				setSnack(null);
			}, 5000);
		}
	};

	const setJourneyCancelled = async (cancelled: boolean) => {
		if (!journey) {
			return;
		}
		setSnack({ message: 'Processing...', severity: 'info' });
		try {
			if (!cancelled) {
				const response = window.confirm('Are you sure you want to activate and process this journey?');
				if (!response) {
					return;
				}
			}
			await API.setJourneyCancelled(journey.customerId, cancelled);
			setSnack({ message: cancelled ? 'Journey cancelled' : 'Journey activated', severity: 'success' });
			await loadData();
		} catch (e) {
			console.error(e);
			setSnack({ message: `Failed to ${cancelled ? 'cancel' : 'activate'} journey`, severity: 'error' });
		} finally {
			setTimeout(() => {
				setSnack(null);
			}, 5000);
		}
	};

	const handleNewEvent = (event: AiFiEvent | ScanEvent | CheckinEvent | TapAppInEvent) => {
		if (event.type === EventType.CHECKIN) {
			if ((event as CheckinEvent).body.customerId === journey?.customerId) {
				setEvents((events) => [event, ...events]);
				setTimeout(() => {
					loadData();
				}, 1000);
			}
		} else if (event.type === EventType.SCAN) {
			if ((event as ScanEvent).body.code === journey?.verificationCode || (event as ScanEvent).body.code === journey?.exitCode) {
				setEvents((events) => [event, ...events]);
				setTimeout(() => {
					loadData();
				}, 1000);
			}
		} else if (event.type === EventType.TAP_IN) {
			if ((event as TapAppInEvent).body.customerId === journey?.customerId) {
				setEvents((events) => [event, ...events]);
				setTimeout(() => {
					loadData();
				}, 1000);
			}
		} else if ((event as AiFiEvent).body.sessionId === String(journey?.aifiSessionId)) {
			setEvents((events) => [event, ...events]);
			setTimeout(() => {
				loadData();
			}, 1000);
		}
	};

	React.useEffect(() => {
		loadData();
	}, [query]);

	React.useEffect(() => {
		eventEmitter.addListener('storeEvent', handleNewEvent);

		return () => {
			eventEmitter.removeListener('storeEvent', handleNewEvent);
		};
	}, [query, journey]);

	if (state !== 'completed') {
		return (
			<Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
				<Typography variant='h4' component='h1' gutterBottom>
					Journey Details
				</Typography>
				<Divider sx={{ mb: 4 }} />
				<Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Paper sx={{ p: 2, display: 'flex', flexDirection: 'row' }}>
								{state === 'error' ? <Error sx={{ mt: 'auto', mb: 'auto' }} /> : <HourglassBottom sx={{ mt: 'auto', mb: 'auto' }} />}
								<Typography variant='h6' component='div' sx={{ mt: 'auto', mb: 'auto', ml: 2 }}>
									{state === 'error' ? 'Error' : 'Loading...'}
								</Typography>
							</Paper>
						</Grid>
					</Grid>
				</Container>
			</Container>
		);
	}

	return (
		<Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
			{journey ? (
				<>
					{snack ? (
						<Snackbar open={true} autoHideDuration={1000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
							<Alert severity={snack.severity} sx={{ width: '100%' }}>
								{snack.message}
							</Alert>
						</Snackbar>
					) : null}

					<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
						<Typography variant='h4' component='h1' gutterBottom>
							Journey Details
						</Typography>
						<Box sx={{ display: 'flex', alignItems: 'space-between' }}>
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								{!(!journey.cancelled && journey.state === CustomerJourneyState.COMPLETED) ? (
									<RoleGate requiredRole={[StaffRole.ROOT, StaffRole.STORE_EMPLOYEE]}>
										<Box sx={{ display: 'flex', alignItems: 'center' }}>
											<Button
												variant='outlined'
												color='primary'
												startIcon={journey.cancelled ? <Replay /> : <Error />}
												sx={{ mr: 1 }}
												onClick={() => setJourneyCancelled(!journey.cancelled)}
											>
												{journey.cancelled ? 'Activate Journey' : 'Cancel Journey'}
											</Button>
										</Box>
									</RoleGate>
								) : null}
								<Button variant='outlined' color='primary' startIcon={<AccountCircle />} href={`/customer?sub=${journey.sub}`} sx={{ mr: 1 }}>
									View Customer
								</Button>
							</Box>
						</Box>
					</Box>
					<Divider sx={{ mb: 4 }} />
					<JourneyProgressBar journey={journey} />
					<Divider sx={{ mb: 4, mt: 4 }} />
					<Grid container spacing={3}>
						<Grid item xs={12} md={6} lg={6}>
							<Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
								<Typography color='text.secondary' gutterBottom sx={{ pb: 2 }}>
									Details
								</Typography>
								<Grid container spacing={3}>
									{journey.type === JourneyType.APP_IN && (
										<Grid
											item
											xs={12}
											md={12}
											lg={6}
											sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', p: 2, mt: 'auto' }}
										>
											<Smartphone sx={{ fontSize: 30 }} color='action' />
											<Typography color='text.secondary'>App Journey</Typography>
										</Grid>
									)}
									{journey.type === JourneyType.TAP_IN && (
										<Grid
											item
											xs={12}
											md={12}
											lg={6}
											sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', p: 2, mt: 'auto' }}
										>
											<Contactless sx={{ fontSize: 30 }} color='action' />
											<Typography color='text.secondary'>Tap In Journey</Typography>
										</Grid>
									)}
									{journey.maxCustomerAmount > 0 && (
										<Grid
											item
											xs={12}
											md={12}
											lg={6}
											sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', p: 2, mt: 'auto' }}
										>
											{journey.maxCustomerAmount <= 1 ? (
												<Person sx={{ fontSize: 30 }} color='action' />
											) : (
												<Group sx={{ fontSize: 30 }} color='action' />
											)}
											<Typography color='text.secondary'>
												{journey.maxCustomerAmount <= 1 ? 'Individual' : `Group of ${journey.maxCustomerAmount}`}
											</Typography>
										</Grid>
									)}
									{journey.entryGateId && (
										<Grid
											item
											xs={12}
											md={12}
											lg={6}
											sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', p: 2, mt: 'auto' }}
										>
											<DoorSlidingOutlined sx={{ fontSize: 30 }} color='action' />
											<Typography color='text.secondary'>
												{'Entry Gate ' + journey.entryGateId.substring(journey.entryGateId.length - 1)}
											</Typography>
										</Grid>
									)}
									{journey.ageVerificationNecessary && (
										<Grid
											item
											xs={12}
											md={12}
											lg={6}
											sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', p: 2, mt: 'auto' }}
										>
											<Liquor sx={{ fontSize: 30 }} color='action' />
											<Typography color='text.secondary'>Restricted Items Detected</Typography>
										</Grid>
									)}
									{journey.ageVerificationSuccessful && (
										<Grid
											item
											xs={12}
											md={12}
											lg={6}
											sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', p: 2, mt: 'auto' }}
										>
											<Badge sx={{ fontSize: 30 }} color='action' />
											<Typography color='text.secondary'>Age Verified</Typography>
										</Grid>
									)}
									{journey.suspiciousBehaviourDetected && (
										<Grid
											item
											xs={12}
											md={12}
											lg={6}
											sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', p: 2, mt: 'auto' }}
										>
											<ProductionQuantityLimits sx={{ fontSize: 30 }} color='action' />
											<Typography color='text.secondary'>Suspicious Behaviour Detected</Typography>
										</Grid>
									)}
									{journey.interactionCount !== null && (
										<Grid
											item
											xs={12}
											md={12}
											lg={6}
											sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', p: 2, mt: 'auto' }}
										>
											<AutoAwesome sx={{ fontSize: 30 }} color='action' />
											<Typography color='text.secondary'>
												{journey.interactionCount} interaction{journey.interactionCount === 1 ? '' : 's'}
											</Typography>
										</Grid>
									)}
									{journey.restrictedInteractionCount !== null && (
										<Grid
											item
											xs={12}
											md={12}
											lg={6}
											sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', p: 2, mt: 'auto' }}
										>
											<SwitchAccessShortcut sx={{ fontSize: 30 }} color='action' />
											<Typography color='text.secondary'>
												{journey.restrictedInteractionCount} restricted interaction{journey.restrictedInteractionCount === 1 ? '' : 's'}
											</Typography>
										</Grid>
									)}
								</Grid>
							</Paper>
						</Grid>
						{API.config.environment === 'dev' && <JourneyEventSimulationControls journey={journey} reload={loadData} liveReceipt={liveReceipt} />}
						<Grid item xs={12} md={6} lg={6}>
							<Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
								<Typography color='text.secondary' gutterBottom>
									IDs
								</Typography>
								{journey.customerId && (
									<Typography variant='body2' gutterBottom>
										Journey Customer ID: {journey.customerId}
									</Typography>
								)}
								{journey.aifiSessionId && (
									<Typography variant='body2' gutterBottom>
										AiFi Session ID: {journey.aifiSessionId}
									</Typography>
								)}
								{journey.sub && (
									<>
										<Typography variant='body2' gutterBottom>
											Spryker Customer ID: {JSON.parse(journey.sub).id_customer}
										</Typography>
										<Typography variant='body2' gutterBottom>
											Spryker Customer Reference: {JSON.parse(journey.sub).customer_reference}
										</Typography>
									</>
								)}
								{journey.transactionId && (
									<Typography variant='body2' gutterBottom>
										Receipt No. for Receipt Portal: {journey.transactionId}
									</Typography>
								)}
							</Paper>
						</Grid>
						{liveReceipt.length > 0 && (
							<Grid item xs={12} md={6} lg={6}>
								<Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
									<>
										<Typography color='text.secondary' gutterBottom>
											AiFi Live Receipt
										</Typography>
										{liveReceipt.map((item: AiFiProduct) => {
											return (
												<Typography variant='body2' gutterBottom key={item.barcode}>
													{item.quantity} x {item.name} ({item.barcode})
												</Typography>
											);
										})}
									</>
								</Paper>
							</Grid>
						)}
						<Grid item xs={12} md={12} lg={12}>
							<Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
								<Typography color='text.secondary' gutterBottom>
									Events
								</Typography>
								{events.map((event, index) => {
									return <EventItem event={event} key={event.timestamp + event.type} displayButton={false} displayFullDate={true} />;
								})}
							</Paper>
						</Grid>
					</Grid>
					{orders.map((order: DashboardOrder) => {
						return (
							<Box key={order.orderReceiptId}>
								<Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
									<Typography variant='h5' component='h1' gutterBottom>
										{order.type === OrderType.ORDER ? 'Order' : order.type === OrderType.UPDATED_ORDER ? 'Updated Order' : 'Refund Order'}
									</Typography>
									<Box sx={{ display: 'flex', alignItems: 'center' }}>
										<Typography variant='subtitle2' component='h1' gutterBottom sx={{ mr: 1 }}>
											{(() => {
												if (journey.cancelled) {
													return 'Journey cancelled, order will not be processed';
												}
												if (order.bopState === BopState.COMPLETED) {
													return 'Inserted in Store 2014';
												}
												if (order.paymentState === PaymentState.PRICING_PENDING) {
													return 'Store 2014 insert waiting for pricing';
												}
												if (order.bopState === BopState.UPDATED_ORDER_PENDING) {
													return 'Store 2014 insert partially pending';
												}
												if (order.bopState === BopState.CANCELLED) {
													return 'Store 2014 insert cancelled';
												}
												return 'Store 2014 insert pending';
											})()}
										</Typography>
										{(() => {
											if (journey.cancelled || order.bopState === BopState.CANCELLED) {
												return null;
											}
											if (order.bopState === BopState.COMPLETED) {
												return <Store sx={{ mb: 1 }} color='action' />;
											}
											if (order.paymentState === PaymentState.PRICING_PENDING) {
												return <HourglassBottom sx={{ mb: 1 }} color='action' />;
											}

											return <Error sx={{ mb: 1 }} color='error' />;
										})()}
									</Box>
								</Box>
								<Divider sx={{ mb: 4 }} />
								<OrderPaymentProgressBar stateMillis={order.paymentStateMillis} activeState={order.paymentState} type={order.type} />

								<Divider sx={{ mb: 4, mt: 4 }} />
								<Grid container spacing={3}>
									<Grid item xs={12} md={6} lg={6}>
										<Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
											{order.type !== OrderType.REFUND ? (
												<>
													<Typography color='text.secondary' gutterBottom>
														Products
													</Typography>
													{order.cart?.items.map((item: any) => {
														const manualOverride = order.manualProductOverrides?.find(
															(override: ManualProductOverride) => override.originalBarcode === item.code
														);
														return (
															<>
																<Typography variant='body2' gutterBottom>
																	{item.amount} x {item.name} ({item.code})
																</Typography>
																{manualOverride &&
																	manualOverride.updatedBarcode == null &&
																	manualOverride.updatedPrice == null && (
																		<Typography variant='body2' color='error' gutterBottom sx={{ ml: 2 }}>
																			Pricing failed
																		</Typography>
																	)}
																{manualOverride && manualOverride.updatedBarcode != null && (
																	<Typography variant='body2' color='error' gutterBottom sx={{ ml: 2 }}>
																		Pricing failed, using {manualOverride.updatedBarcode} instead
																	</Typography>
																)}
															</>
														);
													})}
													{order.cart?.items.length === 0 && (
														<Typography variant='body2' gutterBottom>
															No Products
														</Typography>
													)}
												</>
											) : (
												<>
													<Typography color='text.secondary' gutterBottom>
														Remaining Products
													</Typography>
													{order.refundRequest?.items.map((item: any) => {
														return (
															<Typography variant='body2' gutterBottom>
																{item.itemCount} x {item.upc}
															</Typography>
														);
													})}
													{order.refundRequest?.items.length === 0 && (
														<Typography variant='body2' gutterBottom>
															No Products
														</Typography>
													)}
													<Typography color='text.secondary' gutterBottom>
														Refunded Products
													</Typography>
													{order.refundRequest?.refundItems.map((item: any) => {
														return (
															<Typography variant='body2' gutterBottom>
																{item.upc}: {item.reason}
															</Typography>
														);
													})}
													{order.refundRequest?.refundItems.length === 0 && (
														<Typography variant='body2' gutterBottom>
															No Products
														</Typography>
													)}
												</>
											)}
										</Paper>
									</Grid>
									{((order.cart != null && order.cart.orderId != null) ||
										(order.sprykerOrderReference && order.sprykerOrderReference !== 'LEGACY_ORDER')) && (
										<Grid item xs={12} md={6} lg={6}>
											<Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
												<Typography color='text.secondary' gutterBottom>
													Actions
												</Typography>
												{!(
													(order.paymentState === PaymentState.COMPLETED ||
														order.paymentState === PaymentState.PAYMENT_PENDING ||
														order.paymentState === PaymentState.PARTIAL_PAYMENT_PENDING) &&
													order.bopState === BopState.COMPLETED
												) && order.paymentState !== PaymentState.CANCELLED ? (
													<RoleGate requiredRole={StaffRole.ROOT}>
														<Button
															variant='outlined'
															color='primary'
															startIcon={<Replay />}
															sx={{ mt: 1 }}
															onClick={() => retryOrder(order)}
														>
															Restart ALDI Processing
														</Button>
													</RoleGate>
												) : null}
												{order.sprykerOrderReference && order.sprykerOrderReference !== 'LEGACY_ORDER' && (
													<Button
														variant='outlined'
														color='primary'
														startIcon={<ReceiptLong />}
														sx={{ mt: 1 }}
														onClick={() => {
															window.open(`${API.config.sprykerBaseUrl}/sales/detail?id-receipt=${order.orderReceiptId}`);
														}}
													>
														View{' '}
														{order.type === OrderType.ORDER
															? 'Order'
															: order.type === OrderType.UPDATED_ORDER
															? 'Updated Order'
															: 'Refund Order'}{' '}
														{order.sprykerOrderReference === 'WAS_ALREADY_IN_SPRYKER' ? '' : order.sprykerOrderReference} in Spryker
													</Button>
												)}
												{order.cart != null && order.cart.orderId != null && (
													<Button
														variant='outlined'
														color='primary'
														sx={{ mt: 1 }}
														startIcon={<Videocam />}
														onClick={() => {
															window.open(`${API.config.aifiBaseUrl}/${order.cart?.orderId}`);
														}}
													>
														View Order {order.cart.orderId} in AiFi Console
													</Button>
												)}
												<Button
													variant='outlined'
													color='primary'
													sx={{ mt: 1 }}
													startIcon={<Tablet />}
													onClick={() => {
														window.open(`${API.config.receiptPortalBaseUrl}/receipt/${journey.customerId}`);
													}}
												>
													View order on receipt portal
												</Button>
												<Button
													variant='outlined'
													color='primary'
													startIcon={<ContentPaste />}
													onClick={() => {
														navigator.clipboard.writeText(`${API.config.receiptPortalBaseUrl}/receipt/${journey.customerId}`);
														setSnack({ message: 'Copied link to receipt portal', severity: 'success' });
													}}
													sx={{ mt: 1 }}
												>
													Copy link to receipt portal
												</Button>
											</Paper>
										</Grid>
									)}
									<Grid item xs={12} md={6} lg={6}>
										<Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
											<Typography color='text.secondary' gutterBottom>
												IDs
											</Typography>
											{order.orderCustomerId && (
												<Typography variant='body2' gutterBottom>
													Order Customer ID: {order.orderCustomerId}
												</Typography>
											)}
											{order.orderReceiptId != null && (
												<Typography variant='body2' gutterBottom>
													Order Receipt ID: {order.orderReceiptId}
												</Typography>
											)}
											{order.parentReceiptId && (
												<Typography variant='body2' gutterBottom>
													Parent Order Receipt ID: {order.parentReceiptId}
												</Typography>
											)}
											{order.sprykerOrderReference && (
												<Typography variant='body2' gutterBottom>
													Spryker Order Reference: {order.sprykerOrderReference}
												</Typography>
											)}
											{order.cart && order.cart.orderId && (
												<Typography variant='body2' gutterBottom>
													AiFi Order ID: {order.cart.orderId}
												</Typography>
											)}
										</Paper>
									</Grid>
								</Grid>
							</Box>
						);
					})}
				</>
			) : (
				<>
					<Typography variant='h4' component='h1' gutterBottom>
						Journey Details
					</Typography>
					<Divider sx={{ mb: 4 }} />
					<Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
									<Typography variant='h6' gutterBottom component='div'>
										No Journey Found
									</Typography>
									<Typography color='text.secondary' gutterBottom>
										No customer journey was found for the specified criteria. This might be due to StaffApp codes being used.
									</Typography>
								</Paper>
							</Grid>
						</Grid>
					</Container>
				</>
			)}
		</Container>
	);
};

export default JourneyDetailPage;
