import React from 'react';
import { useLocation } from 'react-router-dom';
import DateSelector from '../components/General/DateSelector';
import WriteOffOverview from '../components/WriteOff/WriteOffOverview';

function useQuery() {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}

const WriteOffPage = () => {
	const query = useQuery();

	const startDate = query.get('startDate') ? new Date(query.get('startDate')!) : null;
	const endDate = query.get('endDate') ? new Date(query.get('endDate')!) : null;

	if (!startDate || !endDate) return <DateSelector title='Write-Offs' buttonTitle='Get Write-Offs' basePath='/writeoff' />;

	return <WriteOffOverview startDate={startDate} endDate={endDate} />;
};

export default WriteOffPage;
