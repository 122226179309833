import React, { useEffect } from 'react';
import Container from '@mui/material/Container';
import { Box, Button, Chip, Divider, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import {
	AccountCircle,
	Badge,
	Contactless,
	DoorSliding,
	FormatListBulleted,
	HourglassBottom,
	Liquor,
	QrCodeScanner,
	ReceiptLong,
	Screenshot,
	Smartphone,
	Store,
	Videocam,
} from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import API from '../datalayer/API';
import DashboardJourney, { CustomerJourneyState, JourneyType } from '../models/DashboardJourney';
import { DateTimeSelector } from '../components/DateTimeSelector';
import ScrollEventEmitter from '../datalayer/ScrollEventEmitter';

function useQuery() {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}

const JourneyPage = () => {
	const query = useQuery();
	const navigate = useNavigate();

	const [loading, setLoading] = React.useState(false);
	const [hasMore, setHasMore] = React.useState(true);

	const selectedStartDate: string | undefined = query.get('startDate') ? new Date(query.get('startDate') as string).toISOString() : undefined;
	const selectedEndDate: string | undefined = query.get('endDate') ? new Date(query.get('endDate') as string).toISOString() : undefined;

	const selectedState: CustomerJourneyState | undefined = (query.get('state') ?? undefined) as CustomerJourneyState | undefined;
	const selectedType: JourneyType | undefined = (query.get('type') ?? undefined) as JourneyType | undefined;
	const sub: string | undefined = query.get('sub') ?? undefined;

	const setSelectedStartDate = (startDate: string | undefined) => {
		const params = new URLSearchParams();
		if (startDate) params.append('startDate', startDate);
		if (selectedEndDate) params.append('endDate', selectedEndDate);
		if (selectedState) params.append('state', selectedState);
		if (selectedType) params.append('type', selectedType);
		navigate(`/journeys?${params.toString()}`);
	};

	const setSelectedEndDate = (endDate: string | undefined) => {
		const params = new URLSearchParams();
		if (selectedStartDate) params.append('startDate', selectedStartDate);
		if (endDate) params.append('endDate', endDate);
		if (selectedState) params.append('state', selectedState);
		if (selectedType) params.append('type', selectedType);
		navigate(`/journeys?${params.toString()}`);
	};

	const setSelectedState = (selectedState: CustomerJourneyState | undefined) => {
		const params = new URLSearchParams();
		if (selectedStartDate) params.append('startDate', selectedStartDate);
		if (selectedEndDate) params.append('endDate', selectedEndDate);
		if (selectedState) params.append('state', selectedState);
		if (selectedType) params.append('type', selectedType);
		navigate(`/journeys?${params.toString()}`);
	};
	const setSelectedType = (selectedType: JourneyType | undefined) => {
		const params = new URLSearchParams();
		if (selectedStartDate) params.append('startDate', selectedStartDate);
		if (selectedEndDate) params.append('endDate', selectedEndDate);
		if (selectedState) params.append('state', selectedState);
		if (selectedType) params.append('type', selectedType);
		navigate(`/journeys?${params.toString()}`);
	};

	const [journeys, setJourneys] = React.useState<Array<DashboardJourney>>([]);
	const [amountForFilter, setAmountForFilter] = React.useState<number | null>(null);

	const fetchJourneys = async ({ limit, lastId, reset }: { limit: number; lastId?: string; reset?: boolean }) => {
		setLoading(true);
		API.getJourneys({
			limit,
			lastId,
			state: selectedState,
			type: selectedType,
			startDate: selectedStartDate ? new Date(selectedStartDate) : undefined,
			endDate: selectedEndDate ? new Date(selectedEndDate) : undefined,
		})
			.then((data) => {
				setHasMore(data.length === limit);
				if (reset) {
					setJourneys(data);
				} else {
					setJourneys([...journeys, ...data]);
				}
				setLoading(false);
			})
			.catch((e) => {
				console.error(e);
				setLoading(false);
			});
	};

	useEffect(() => {
		if (sub) {
			navigate(`/customer?sub=${sub}`);
			return;
		}

		fetchJourneys({ limit: 20, reset: true });
		getJourneyAmount();
	}, [selectedState, selectedType, selectedStartDate, selectedEndDate]);

	const getJourneyAmount = async () => {
		setAmountForFilter(
			await API.getJourneyAmount({
				state: selectedState,
				type: selectedType,
				startDate: selectedStartDate ? new Date(selectedStartDate) : undefined,
				endDate: selectedEndDate ? new Date(selectedEndDate) : undefined,
			})
		);
	};

	useEffect(() => {
		const bottomOfPageReached = () => {
			if (hasMore && !loading) {
				const lastId = journeys.at(-1)?._id;
				fetchJourneys({ limit: 20, lastId });
			}
		};
		ScrollEventEmitter.on('bottom', bottomOfPageReached);
		return () => {
			ScrollEventEmitter.off('bottom', bottomOfPageReached);
		};
	}, [journeys, hasMore, loading]);

	return (
		<Container maxWidth='xl' sx={{ mt: 4, mb: 4 }}>
			<Paper
				sx={{
					display: 'flex',
					flexDirection: 'column',
					mb: 4,
				}}
			>
				<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', pt: 2, pl: 2, pr: 2, pb: 2 }}>
					<Typography variant='h6'>Journeys</Typography>
					<Typography variant='body1'>{amountForFilter != null ? `journeys for current filter: ${amountForFilter}` : ''}</Typography>
				</Box>

				<>
					<Divider />
					<DateTimeSelector
						startDate={selectedStartDate ? new Date(selectedStartDate) : undefined}
						endDate={selectedEndDate ? new Date(selectedEndDate) : undefined}
						setStartDate={setSelectedStartDate}
						setEndDate={setSelectedEndDate}
					/>
					<Divider />
					<Grid container spacing={1} sx={{ pt: 2, pl: 2, pr: 2, pb: 2 }}>
						<Typography variant='subtitle1' sx={{ mt: 'auto', mb: 'auto', ml: 1, mr: 1 }}>
							State
						</Typography>
						<Grid item>
							<Chip
								variant={!selectedState ? 'filled' : 'outlined'}
								size='medium'
								label='Show All'
								icon={<FormatListBulleted />}
								onClick={() => {
									setSelectedState(undefined);
								}}
							/>
						</Grid>
						<Grid item>
							<Chip
								variant={selectedState === CustomerJourneyState.ANNOUNCEMENT ? 'filled' : 'outlined'}
								size='medium'
								label='QR Code Generated'
								icon={<QrCodeScanner />}
								onClick={() => {
									setSelectedState(CustomerJourneyState.ANNOUNCEMENT);
								}}
							/>
						</Grid>
						<Grid item>
							<Chip
								variant={selectedState === CustomerJourneyState.SCAN ? 'filled' : 'outlined'}
								size='medium'
								label='QR Code Scanned'
								icon={<DoorSliding />}
								onClick={() => {
									setSelectedState(CustomerJourneyState.SCAN);
								}}
							/>
						</Grid>
						<Grid item>
							<Chip
								variant={selectedState === CustomerJourneyState.CHECKIN ? 'filled' : 'outlined'}
								size='medium'
								label='Currently In Store'
								icon={<Store />}
								onClick={() => {
									setSelectedState(CustomerJourneyState.CHECKIN);
								}}
							/>
						</Grid>
						<Grid item>
							<Chip
								variant={selectedState === CustomerJourneyState.LEFT ? 'filled' : 'outlined'}
								size='medium'
								label='Waiting for their Receipt'
								icon={<HourglassBottom />}
								onClick={() => {
									setSelectedState(CustomerJourneyState.LEFT);
								}}
							/>
						</Grid>
						<Grid item>
							<Chip
								variant={selectedState === CustomerJourneyState.COMPLETED ? 'filled' : 'outlined'}
								size='medium'
								label={CustomerJourneyState.COMPLETED}
								icon={<ReceiptLong />}
								onClick={() => {
									setSelectedState(CustomerJourneyState.COMPLETED);
								}}
							/>
						</Grid>
					</Grid>
					<Divider />

					<Grid container spacing={1} sx={{ pt: 2, pl: 2, pr: 2, pb: 2 }}>
						<Typography variant='subtitle1' sx={{ mt: 'auto', mb: 'auto', ml: 1, mr: 1 }}>
							Journey Type
						</Typography>
						<Grid item>
							<Chip
								variant={!selectedType ? 'filled' : 'outlined'}
								size='medium'
								label='Show All'
								icon={<FormatListBulleted />}
								onClick={() => {
									setSelectedType(undefined);
								}}
							/>
						</Grid>
						<Grid item>
							<Chip
								variant={selectedType === JourneyType.APP_IN ? 'filled' : 'outlined'}
								size='medium'
								label='App'
								icon={<Smartphone />}
								onClick={() => {
									setSelectedType(JourneyType.APP_IN);
								}}
							/>
						</Grid>
						<Grid item>
							<Chip
								variant={selectedType === JourneyType.TAP_IN ? 'filled' : 'outlined'}
								size='medium'
								label='Tap In'
								icon={<Contactless />}
								onClick={() => {
									setSelectedType(JourneyType.TAP_IN);
								}}
							/>
						</Grid>
					</Grid>
				</>
			</Paper>
			<Paper sx={{ width: '100%', mb: 2 }}>
				<TableContainer>
					<Table sx={{ minWidth: 650 }} aria-label='simple table'>
						<TableHead>
							<TableRow>
								<TableCell>Created At</TableCell>
								<TableCell align='right'>Journey ID</TableCell>
								<TableCell align='right'>Spryker Customer ID</TableCell>
								<TableCell align='right'>Type</TableCell>
								<TableCell align='right'>State</TableCell>
								<TableCell align='right'>Group Size</TableCell>
								<TableCell align='right'>Entry Gate ID</TableCell>
								<TableCell align='right'>Entry Time</TableCell>
								<TableCell align='right'>Exit Time</TableCell>
								<TableCell align='right'>Age Restriction</TableCell>
								<TableCell align='right'>Actions</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{journeys.map((journey: DashboardJourney) => (
								<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={journey.customerId}>
									<TableCell component='th' scope='row'>
										{journey.stateMillis[CustomerJourneyState.ANNOUNCEMENT]
											? new Date(journey.stateMillis[CustomerJourneyState.ANNOUNCEMENT]).toLocaleString()
											: ''}
									</TableCell>
									<TableCell align='right'>{journey.customerId}</TableCell>
									<TableCell align='right'>
										{JSON.parse(journey.sub)?.customer_reference}
										<br />
										{JSON.parse(journey.sub)?.id_customer}
									</TableCell>
									<TableCell align='right'>
										{journey.type === JourneyType.APP_IN && <Smartphone color='action' />}
										{journey.type === JourneyType.TAP_IN && <Contactless color='action' />}
									</TableCell>
									<TableCell align='right'>
										{journey.state === CustomerJourneyState.ANNOUNCEMENT && <QrCodeScanner color='action' />}
										{journey.state === CustomerJourneyState.SCAN && <DoorSliding color='action' />}
										{journey.state === CustomerJourneyState.CHECKIN && <Store color='action' />}
										{journey.state === CustomerJourneyState.LEFT && <HourglassBottom color='action' />}
										{journey.state === CustomerJourneyState.COMPLETED && <ReceiptLong color='action' />}
									</TableCell>

									<TableCell align='right'>{journey.maxCustomerAmount}</TableCell>
									<TableCell align='right'>
										{journey.entryGateId ? journey.entryGateId.substring(journey.entryGateId.length - 1) : ''}
									</TableCell>
									<TableCell align='right'>
										{journey.stateMillis[CustomerJourneyState.CHECKIN]
											? new Date(journey.stateMillis[CustomerJourneyState.CHECKIN]).toLocaleString()
											: ''}
									</TableCell>
									<TableCell align='right'>
										{journey.stateMillis[CustomerJourneyState.LEFT]
											? new Date(journey.stateMillis[CustomerJourneyState.LEFT]).toLocaleString()
											: ''}
									</TableCell>
									<TableCell align='right'>
										{journey.ageVerificationNecessary && <Liquor color='action' />}
										{journey.ageVerificationSuccessful && <Badge color='action' />}
									</TableCell>
									<TableCell align='right'>
										<Button variant='outlined' target='_blank' href={`/journey?customerId=${journey.customerId}`}>
											Details
										</Button>
									</TableCell>
								</TableRow>
							))}
							{loading ? (
								<TableRow>
									<TableCell align='center' colSpan={100}>
										<Typography variant='body2'>Loading...</Typography>
									</TableCell>
								</TableRow>
							) : null}
							{!hasMore ? (
								<TableRow>
									<TableCell align='center' colSpan={100}>
										<Typography variant='body2'>
											{journeys.length === 0 ? 'No journeys found for current filter' : 'You have reached the end of the list'}
										</Typography>
									</TableCell>
								</TableRow>
							) : null}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</Container>
	);
};

export default JourneyPage;
