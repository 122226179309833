import React from 'react';
import { useLocation } from 'react-router-dom';
import AccuracyOverview from '../components/Accuracy/AccuracyOverview';
import DateSelector from '../components/General/DateSelector';

function useQuery() {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}

const AccuracyPage = () => {
	const query = useQuery();

	const startDate = query.get('startDate') ? new Date(query.get('startDate')!) : null;
	const endDate = query.get('endDate') ? new Date(query.get('endDate')!) : null;

	if (!startDate || !endDate) return <DateSelector title='Accuracy Testing' buttonTitle='Calculate Accuracy' basePath='/accuracy' />;

	return <AccuracyOverview startDate={startDate} endDate={endDate} />;
};

export default AccuracyPage;
